export default function Contact() {
    return(
        <>
          <h1>Contact</h1>
          <p>Lorem ipsum dolor sit amet. Est deserunt veniam quo dolor sequi et voluptas delectus ab distinctio recusandae eos deleniti natus est ipsam eius? Ab quam tempore aut tempora quia ut deleniti quidem non alias quidem 33 corrupti illo. Ab atque sint hic quos culpa sed alias ullam. Sit reprehenderit ipsam eos modi eligendi eos nihil dolores. </p><p>Hic voluptas obcaecati ut natus repudiandae ut explicabo quis. Et quia doloremque id consequatur veritatis aut excepturi fuga aut quos velit est saepe tempora rem quidem voluptatem aut consequuntur laboriosam. </p><p>Et omnis nisi qui perferendis praesentium qui fugiat tempora ut sunt quia. Eos minima sunt eos totam soluta cum odit enim eos aliquam necessitatibus. Et praesentium atque et quod minus id voluptatem voluptatum ad magnam soluta id animi accusantium? </p><br /><br /><br />
          <h2>H2 Heading</h2>
          <p>Lorem ipsum dolor sit amet. Est deserunt veniam quo dolor sequi et voluptas delectus ab distinctio recusandae eos deleniti natus est ipsam eius? Ab quam tempore aut tempora quia ut deleniti quidem non alias quidem 33 corrupti illo. Ab atque sint hic quos culpa sed alias ullam. Sit reprehenderit ipsam eos modi eligendi eos nihil dolores. </p><p>Hic voluptas obcaecati ut natus repudiandae ut explicabo quis. Et quia doloremque id consequatur veritatis aut excepturi fuga aut quos velit est saepe tempora rem quidem voluptatem aut consequuntur laboriosam. </p><p>Et omnis nisi qui perferendis praesentium qui fugiat tempora ut sunt quia. Eos minima sunt eos totam soluta cum odit enim eos aliquam necessitatibus. Et praesentium atque et quod minus id voluptatem voluptatum ad magnam soluta id animi accusantium? </p><br /><br /><br />
          <h3>H3 heading</h3>
          <p>Lorem ipsum dolor sit amet. Est deserunt veniam quo dolor sequi et voluptas delectus ab distinctio recusandae eos deleniti natus est ipsam eius? Ab quam tempore aut tempora quia ut deleniti quidem non alias quidem 33 corrupti illo. Ab atque sint hic quos culpa sed alias ullam. Sit reprehenderit ipsam eos modi eligendi eos nihil dolores. </p><p>Hic voluptas obcaecati ut natus repudiandae ut explicabo quis. Et quia doloremque id consequatur veritatis aut excepturi fuga aut quos velit est saepe tempora rem quidem voluptatem aut consequuntur laboriosam. </p><p>Et omnis nisi qui perferendis praesentium qui fugiat tempora ut sunt quia. Eos minima sunt eos totam soluta cum odit enim eos aliquam necessitatibus. Et praesentium atque et quod minus id voluptatem voluptatum ad magnam soluta id animi accusantium? </p><br /><br /><br />
        </>
    );
}